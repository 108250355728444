<template>
       <div class="max_div" v-loading="loading" element-loading-text="拼命加载中" element-loading-spinner="el-icon-loading"
        element-loading-background="rgba(255, 255, 255, 0.8)">

            <div class="top_div">
                <div class="block_div">
                    <el-row :gutter="20">
                        <el-col :span="4">
                            <div class="block">
                                <div class="data_div">
                                    活动总数
                                </div>
                                <div class="data_div">
                                    {{this.count.num1}}
                                </div>
                            </div>
                        </el-col>
                        <el-col :span="4">
                            <div class="block">
                                <div class="data_div">
                                    已结束
                                </div>
                                <div class="data_div"> 
                                    {{this.count.num2}}
                                </div>
                            </div>
                        </el-col>
                        
                        <el-col :span="4">
                            <div class="block">
                                <div class="data_div">
                                    正在进行的活动
                                </div>
                                <div class="data_div">
                                    {{this.count.num3}}
                                </div>
                            </div>
                        </el-col>
                        <el-col :span="4">
                            <div class="block">
                                <div class="data_div">
                                    累计报名人数
                                </div>
                                <div class="data_div">
                                    {{this.count.num4}}
                                </div>
                            </div>
                        </el-col>
                        
                    </el-row>
                </div>
                <!-- <div v-else class="block_div">
                    <el-row :gutter="20">
                        <el-col :span="4">
                            <div class="block">
                                <div class="data_div">
                                    活动总数
                                </div>
                                <div class="data_div">
                                    1
                                </div>
                            </div>
                        </el-col>
                        <el-col :span="4">
                            <div class="block">
                                <div class="data_div">
                                    已结束
                                </div>
                                <div class="data_div"> 
                                    0
                                </div>
                            </div>
                        </el-col>
                        
                        <el-col :span="4">
                            <div class="block">
                                <div class="data_div">
                                    进行中
                                </div>
                                <div class="data_div">
                                    1
                                </div>
                            </div>
                        </el-col>
                        <el-col :span="4">
                            <div class="block">
                                <div class="data_div">
                                    累计参与学生
                                </div>
                                <div class="data_div">
                                    874
                                </div>
                            </div>
                        </el-col>
                        <el-col :span="4">
                            <div class="block">
                                <div class="data_div">
                                    累计参与家长
                                </div>
                                <div class="data_div">
                                    1205
                                </div>
                            </div>
                        </el-col>
                        <el-col :span="4">
                            <div class="block">
                                <div class="data_div">
                                    累计参与学校
                                </div>
                                <div class="data_div">
                                    54
                                </div>
                            </div>
                        </el-col>
                        
                    </el-row>
                </div> -->
                <el-row style="margin-top:1vw">
                    <el-col :span="19">
                        <el-input :size="tabsize" v-model="inputData" style="width:15%" placeholder="活动名称"></el-input>

                        <el-select filterable :size="tabsize" v-model="selectData" placeholder="状态" style="width:15%;margin-left:0.5%">
                            <el-option v-for="item in dictList" :key="item.value" :label="item.label" :value="item.value">

                            </el-option>
                        </el-select>

                        <el-button style="margin-left:1%" type="success" :size="tabsize"  @click="getTableData" icon="el-icon-search">搜索</el-button>
                        <el-button style="margin-left:1%" type="primary" :size="tabsize"  @click="remarkClick" icon="el-icon-refresh-left">重置</el-button>  
                    </el-col>
                    <el-col :span="5" >
                        <div style="text-align: end;">
                            <el-button type="primary" :size="tabsize" @click="addTableData(1)" icon="el-icon-plus">创建活动</el-button>
                        </div>
                    </el-col>
                </el-row>
            </div>

            <div class="center_div" style="margin-top:1%">
                <el-table :data="tableData" border stripe :size="tabsize" style="overflow:auto" >
                    <!-- <el-table-column label="编号" prop='id' align='center'>
                        <template slot-scope="scope">
                            <span>{{ scope.row.id }}</span>
                        </template>
                    </el-table-column> -->
                    <el-table-column label="活动名称" prop='code' align='center'>
                        <template slot-scope="scope">
                            <span>{{ scope.row.title}}</span>
                        </template>
                    </el-table-column>

                    <el-table-column label="发布时间" prop='code' align='center'>
                        <template slot-scope="scope">
                            <span>{{ scope.row.fbtime |timestampToTime }}</span>
                        </template>
                    </el-table-column>

                    <el-table-column label="结束时间" prop='code' align='center'>
                        <template slot-scope="scope">
                            <span>{{ scope.row.etime |timestampToTime}}</span>
                        </template>
                    </el-table-column>

                    <el-table-column label="参加人数" prop='code' align='center'>
                        <template slot-scope="scope">
                            <span>{{ scope.row.num }}</span>
                        </template>
                    </el-table-column>

                    <el-table-column label="新报名" prop='code' align='center'>
                        <template slot-scope="scope">
                            <span>{{ scope.row.num1 }}</span>
                        </template>
                    </el-table-column>

                    <el-table-column label="活动状态" prop='code' align='center'>
                        <template slot-scope="scope">
                            <span>{{ scope.row.status | typeFilter(that) }}</span>
                        </template>
                    </el-table-column>

                    <el-table-column label="操作" prop='code' align='center'>
                        <template slot-scope="scope">
                            <div class="button_list_div">
                                <el-button :size="tabsize" icon="el-icon-search"  type="primary" @click="buttonClick(scope.row)">查看</el-button>
                                <!-- <el-button :size="tabsize" icon="el-icon-view" @click="jumpStoreData(scope.row.id)">详情</el-button> -->
                            </div>
                        </template>
                    </el-table-column>
                </el-table>
            </div>

            <div class="footer_div">
                <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="currentPage"
                        :page-sizes="[10, 20, 30, 40]" :page-size="pageSize"  layout="total, sizes, prev, pager, next, jumper"
                        :total="totalRow">
                </el-pagination>
            </div>
        </div>
</template>

<script>
    export default {
        mounted(){
            this.getTableData()
            this.getCount()
            // 状态  0：删除  10：未开始   20：进行中  30：已结束   40：下架
        },
        data(){
            return{
                user:{},
                loading:false,
                currentPage: 1, //默认显示第一页
                pageSize: 10, //默认每页显示10条
                totalNum: '', //总页数
                order: 'desc',
                sort: 'id',
                totalRow:0,
                tableData:[],
                count:'',
                dictList:[
                    {
                        value:'10',
                        label:'未开始'
                    },
                    {
                        value:'20',
                        label:'进行中'
                    },
                    {
                        value:'30',
                        label:'已结束'
                    },
                    {
                        value:'40',
                        label:'下架'
                    }
                ],
                that:this,
                inputData:'',
                selectData:''
            }
        },
        methods:{
            addTableData(){
                this.$router.push({
                    path:'/partyDialog',
                    
                })
            },
            buttonClick(row){
                console.log(row);
                this.$router.push({
                    path:'/partyInfo',
                    query:{
                        id:row.id,
                        count:this.count
                    }
                })
            },
            getCount(){
                this.$api.party.getCount({

                }).then((res)=>{
                   if(res.status == 1){
                        this.count = res.obj
                   }
                }).catch((error)=>{

                })
            },
            getTableData(){
                this.loading = true
                this.$api.party.getTableData({
                    'jPage.pageNum':this.currentPage,
                    'jPage.pageSize':this.pageSize,
                    'jPage.sort':'',
                    'jPage.order':'',
                    'title':this.inputData,
                    'status':this.selectData
                }).then((res)=>{
                    if(res.status == 1){
                        this.tableData = res.obj.list
                        console.log(this.tableData);
                        this.totalRow = res.obj.totalRow
                        this.loading = false
                    }
                }).catch((error)=>{
                    this.$alertError(error.msg)
                })
            },
            remarkClick(){
                this.inputData =''
                this.selectData = ''
                this.getTableData()
            },
            // 分页大小
            handleSizeChange(val) {
                this.loading = true
                this.pageSize = val
                this.currentPage = 1 // 选择分页大小后跳转第一页
                this.getTableData()
            },
            // 第几页
            handleCurrentChange(val) {
                this.loading = true
                this.currentPage = val
                this.getTableData()
            },
        },
        // 监听尺寸按钮变化 改变表格大小
        computed: {
            tabsize() {
                this.$setTableHeight2('top_div','center_div')
                return this.$store.state.tabsize
            }
        },
        filters:{
            typeFilter(val,that){
                for(let i=0;i<that.dictList.length;i++){
                    if(that.dictList[i].value == val){
                        return that.dictList[i].label
                    }
                }
            }
        },
        created() {
            this.user = JSON.parse(sessionStorage.getItem("userInfo"))
        }
    }
</script>

<style scoped>
    .block{
        width: 100%;
        height: 100%;
        background-color: #fff;
        border-radius: 8px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .data_div{
        text-align: center;
        width: 80%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
    .block_div{
        width: 100%;
        height: 8vh;
    }
</style>     